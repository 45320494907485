import svg2 from "../../images/svg-2.svg";
import svg1 from "../../images/svg-1.svg";
import svg3 from "../../images/svg-3.svg";
import found from "../../images/eng_cord.jpeg";
import contact_us from "../../images/donate_orange.svg";
import support from "../../images/donate.jpg";

export const donateObjOne = {
  id: "donate",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Donation",
  headline: "Support Our Projects",
  description1: `We believe that every life matters and our  passion is to see every individual 
  follow their heart, pursue their dreams, receive the love of the Almighty God, and live a life 
  with purpose with the help of your generosity and sharing to raise awareness. We are working to
   save many lives around the world with your donations and sharing.`,

  description2: `Your willingness to donate helps
    to raise awareness. Every little bit counts, especially during 
   these unprecedented times. Please help make a family smile today.
  Are you willing to share and donate to help us raise awareness? 
  We are looking forward to reaching out to you.`,

  buttonLabel: "Donate",
  imgTitle: "",
  imgStart: false,
  img: support,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjThree = {
  id: "signup",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Contact Us",
  headline: "Subtitle goes here",
  description: `We are here to stand in the gap as God ambassadors
    to help individuals with their purpose and to give hope to people who
    feel they have no purpose and no hope`,
  buttonLabel: "Get started",
  imgTitle: "",
  imgStart: false,
  img: contact_us,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};
