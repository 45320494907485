import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const HeroContainer = styled.div`
  background: #ccc;
  max-width: 100%;
  width: 100%;
  height: 800px;
  position: relative;

  @media screen and (max-width: 768px) {
    background: #000;
    max-width: 100%;
    width: 100%;
    height: 200px;
    margin-bottom: 150px;
  }

  @media screen and (maxwidth: 480px) {
    background: #fff;
    max-width: 100%;
    width: 100%;
    height: 100px;
  }
`;

export const HeroBg = styled.div`
  width: 100%;
  background: #ccc;
  height: auto;
  position: relative;
  @media screen and (max-width: 768px) {
    height: 400px;
    margin-bottom: 150px;
  }

  @media screen and (maxwidth: 480px) {
    height: 350px;
  }
`;
export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const ImgBg = styled.img`
  width: 100%;
  max-width: 100%;
  background: #ccc;
  height: 800px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;

  @media screen and (max-width: 768px) {
    height: 350px;
    display: block;
  }

  @media screen and (maxwidth: 480px) {
    height: 350px;
  }
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: fit-content;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    display: block;
  }

  @media screen and (maxwidth: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bolder;
  color: #fff;
  font-size: 68px;
  display: block;
  line-height: 70px;

  @media screen and (max-width: 768px) {
    top: 100%;
    display: block;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    width: 100%;
  }

  @media screen and (maxwidth: 480px) {
    font-size: 7px;
  }
`;

export const HeroBtnWrapper = styled.div`
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  @media screen and (max-width: 768px) {
    top: 154%;
  }

  @media screen and (maxwidth: 480px) {
    top: 83%;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 80px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 80px;
  font-size: 20px;
`;

export const ServicesContainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #010606;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`;

export const ServicesWrapper = styled.div`
  background: none;
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex;
  grid-gap: 30px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }
`;

export const ServicesCard = styled.div`
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: flex;
  align-items: center;
  width: 100%;
  max-height: 400px;
  padding: 5px;
`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 24px;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`;

export const Heading = styled.h1`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 26px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#013a51" : "#013a51")};
`;

export const Text = styled.span`
  text-align: center;
  margin-top: 4px;
  color: #495057;
  font-size: 22px;
`;
