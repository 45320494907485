import React from "react";
import Icon1 from "../../images/svg-1.svg";
import Icon2 from "../../images/svg-2.svg";
import Person from "../../images/person.svg";
import found from "../../images/eng_cord.jpeg";
import usa_cord from "../../images/usa_cord.jpg";
import zam_cord from "../../images/zam_cord.jpg";
import zam_cord2 from "../../images/zam_cord2.jpg";
import zam_cord1 from "../../images/zam_cord1.jpg";
import fin_cord from "../../images/fin_cord.jpg";
import drc_cord from "../../images/drc_cord1.jpg";

import norway_cord from "../../images/norway_cord.jpg";
import president_eng from "../../images/president_eng.jpg";
import eng_ep from "../../images/eng_ep.jpg";
import eng_adviser from "../../images/eng_adviser.jpg";
import sec_eng from "../../images/sec_eng.jpg";
import sec_eng2 from "../../images/sec_eng2.jpg";

import sec_usa from "../../images/sec_usa.jpg";
import sec_zambia from "../../images/sec_zambia.jpg";

import ReactCountryFlag from "react-country-flag";

import {
  ServicesContainer,
  ServicesH1,
  ServicesH2,
  ServicesP,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1> Meet the Team</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={found} />
          <ServicesH2>
            UK <ReactCountryFlag countryCode="gb" svg />
          </ServicesH2>
          <ServicesP>Chanelle Ilunga</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={president_eng} />
          <ServicesH2>
            UK <ReactCountryFlag countryCode="gb" svg />
          </ServicesH2>
          <ServicesP>Pastor Stephine Mukamana</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={sec_eng2} />
          <ServicesH2>
            UK <ReactCountryFlag countryCode="gb" svg />
          </ServicesH2>
          <ServicesP>Deborah Janette</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={eng_ep} />
          <ServicesH2>
            UK <ReactCountryFlag countryCode="gb" svg />
          </ServicesH2>
          <ServicesP>Julienne Lupindula </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={usa_cord} />
          <ServicesH2>
            USA <ReactCountryFlag countryCode="US" svg />
          </ServicesH2>
          <ServicesP>Prophet Gaby Kaleja</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={sec_usa} />
          <ServicesH2>
            USA <ReactCountryFlag countryCode="US" svg />
          </ServicesH2>
          <ServicesP>Lesly kabwende</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={zam_cord} />
          <ServicesH2>
            Zambia <ReactCountryFlag countryCode="zm" svg />
          </ServicesH2>
          <ServicesP>Pastor Peter Tondoy </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={zam_cord1} />
          <ServicesH2>
            Zambia <ReactCountryFlag countryCode="zm" svg />
          </ServicesH2>
          <ServicesP>Pastor Richard Lumingu</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={zam_cord2} />
          <ServicesH2>
            Zambia <ReactCountryFlag countryCode="zm" svg />
          </ServicesH2>
          <ServicesP>Prophetesse Deborah Kabwe </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={sec_zambia} />
          <ServicesH2>
            Zambia <ReactCountryFlag countryCode="zm" svg />
          </ServicesH2>
          <ServicesP>Dorcas Kabengele </ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={norway_cord} />
          <ServicesH2>
            Norway <ReactCountryFlag countryCode="no" svg />
          </ServicesH2>
          <ServicesP>Godeliv Ndwaya Kazadi</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
