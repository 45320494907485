import svg2 from "../../images/svg-2.svg";
import svg1 from "../../images/svg-1.svg";
import svg3 from "../../images/svg-3.svg";
import found from "../../images/eng_cord.jpeg";
import contact_us from "../../images/donate_orange.svg";
import finance from "../../images/finance.jpg";

export const financeObjOne = {
  id: "transparency",
  lightBg: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Financial Transparency",
  headline: "How We Use Your Donations",
  description1: `The foundation has always relied on the generous support of
   private donors to support its operations.`,

  description2: ` IGWTF believes it is crucial for you to know that 
  the donations and support you provide are used effectively and in an
   ethical manner. This is why we bring you reports and impact proclamations 
   that demonstrate accountability and transparency.`,

  description3: `The impact of your generosity Each year IGWTF pauses
   to reflect on our work. We are in a position to assess 
  what we've learned and how we've served. We steward our resources
   carefully so that both our programs and the people we serve receive the
    utmost support.`,

  buttonLabel: "Support Our Project",
  imgTitle: "",
  imgStart: false,
  img: finance,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  fontBig: true,
};
