import svg2 from "../../images/svg-2.svg";
import svg1 from "../../images/svg-1.svg";
import svg3 from "../../images/svg-3.svg";
import found from "../../images/eng_cord.jpeg";
import contact_us from "../../images/donate_orange.svg";
import child from "../../images/child.jpg";

export const childObjOne = {
  id: "sponsorship",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Sponsorship",
  headline: "Sponsor a Child Within (IGWTF)",
  description1: `
  By giving a monthly gift of £30 or £50 to a child in Zambia and Congo, 
  Africa, you're changing their lives for the better. We know that giving 
  a monthly donation to a child who you may never even meet in person is
   an important commitment, but we know the impact you'll make for a child
    who needs your support. `,

  description2: ` Taking part in our sponsorship program provides you with the
   opportunity to get to know the child you sponsor. Your child will depend on 
   your monthly donation to attend school. They will look forward to writing you
    regular messages, sharing their academic progress, and sending video messages 
    periodically through IGWT.`,

  description3: ` In our educational programs, the IGWTF takes a
   lifecycle approach focusing on children at every age: preschool,
    basic education, adolescence, and youth. We are committed to 
    seeing each child we sponsor come to the end of their educational journey.`,

  buttonLabel: "Sponsor a Child",
  imgTitle: "",
  imgStart: true,
  img: child,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
  fontBig: true,
};
