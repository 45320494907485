import svg2 from "../../images/svg-2.svg";
import svg1 from "../../images/svg-1.svg";
import btw_text from "../../images/between_text.jpg";
import found from "../../images/eng_cord.jpeg";
import contact_us from "../../images/donate_orange.svg";
import support from "../../images/donate_green.svg";

export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "About IGWTF",
  headline: "Mission & Vision",
  description4: `We are a Non-Profit Organisation located in the United
    Kingdom founded in 2020. In God We Trust Foundation (IGWTF) believes
    that, every individual in the world has been given a special purpose
    from God.  `,

  description5: `But however not everyone is able to live in the fullness of
    God’s purpose due to the life circumstances surrounding them. Therefore
    for that reason we came into existence, as Word says in Ecclesiastes
    3:1 ”There is a time for everything, and a season for every activity
    under the heavens” `,

  description1: `We are here to stand in the gap as God ambassadors to help 
  individuals with their purpose and to give hope to people who feel they have 
  no purpose and no hope as the Word of God says in Jeremiah 29:11 “For I know
  the plans I have for you,” declares the LORD, “plans to prosper you and not to
   harm you, plans to give you hope and a future” `,

  description2: `Our vision and mission are to provide basic care needs 
  around the world with our main focus on undeveloped countries.
   As we know not everyone around the world has their necessity needs being met due to
    lack of help or help not being enough-or available. `,

  description3: ` Therefore, we are here to help vulnerable
     children, the orphans, people in hopeless situations and people with poor living standards.
      ur aim of our foundation is to achieve transformation by providing self-help initiatives,
       relief, and assistance to venerable people, orphans and widows. We hope and pray that the 
       Lord will help us accomplish our mission and vision as the Word says in Proverbs 16: 9 “A man's heart deviseth his way: 
       but the LORD drieacth steps”.`,

  buttonLabel: "Help a Child",
  imgTitle: "Chanelle Ilunga: Founder",
  imgStart: false,
  img: found,
  img2: btw_text,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Donation",
  headline: "Support Our Projects",
  description: `We are here to stand in the gap as God ambassadors
    to help individuals with their purpose and to give hope to people who
    feel they have no purpose and no hope`,
  buttonLabel: "Get started",
  imgTitle: "",
  imgStart: false,
  img: support,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjThree = {
  id: "signup",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Contact Us",
  headline: "Subtitle goes here",
  description: `We are here to stand in the gap as God ambassadors
    to help individuals with their purpose and to give hope to people who
    feel they have no purpose and no hope`,
  buttonLabel: "Get started",
  imgTitle: "",
  imgStart: false,
  img: contact_us,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};
