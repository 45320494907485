import React from "react";
import { Button } from "../ButtonElement3";
import { useInView } from "react-intersection-observer";
import "../../App.css";
import { Link } from "react-router-dom";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";

const FinanceSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  imgTitle,
  darkText,
  description1,
  description2,
  description3,

  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  fontBig,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  return (
    <div>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>
                  {description1} <p /> {description2} <p /> {description3}
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap
                className={inView ? "slider slider-zoom" : "slider"}
                ref={ref}
              >
                <Img src={img} alt={alt} />
              </ImgWrap>

              <BtnWrap>
                <Button
                  to={{ pathname: "https://gofund.me/e85e894d" }}
                  target="_blank"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact={true}
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                >
                  {buttonLabel}
                </Button>
              </BtnWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </div>
  );
};

export default FinanceSection;
