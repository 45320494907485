import React, { useState } from "react";
import Video from "../../videos/video.mp4";
import bana from "../../images/boy_bana.jpg"; //6,5,4
import { Button } from "../ButtonElement";

import Icon1 from "../../images/svg-1.svg";
import Icon2 from "../../images/svg-2.svg";
import phone from "../../images/support1.svg";

import {
  HeroContainer,
  HeroBg,
  VideoBg,
  ImgBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  ServicesContainer,
  ServicesH1,
  ServicesH2,
  ServicesP,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  Heading,
  Text,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id="home">
      <HeroBg>
        <ImgBg src={bana} />
      </HeroBg>
      <HeroP> IGWT, Where hope come alive...</HeroP>

      <HeroBtnWrapper>
        <Button
          to="about"
          onMouseEnter={onHover}
          onMouseLeave={onHover}
          primary="true"
          dark="true"
          smooth={true}
          duration={500}
          spy={true}
          exact={"true"}
          offset={-80}
        >
          Discover More {hover ? <ArrowForward /> : <ArrowRight />}
        </Button>
      </HeroBtnWrapper>
    </HeroContainer>
  );
};

export default HeroSection;
