import svg2 from "../../images/svg-2.svg";
import svg1 from "../../images/svg-1.svg";
import svg3 from "../../images/svg-3.svg";
import found from "../../images/founder.jpeg";
import contact_us from "../../images/contact_us.svg";
import support from "../../images/support.svg";

export const videoObjOne = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Our Projects",
  headline: "The Zambia Empowerment Project",
  description: `As a charity, we are commited to helping the 
  less privilleged in Africa to find hope and 
  pursure their dreams - Maheba Refugee Camp `,
  buttonLabel: "Support this Project",
  imgTitle: "",
  imgStart: true,
  img: support,
  alt: "Car",
  dark: false,
  primary: false,
  darkText: false,
};
