import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../images/logo.svg";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{}}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo onClick={toggleHome}>
              <img
                src={logo}
                alt="IGWTF"
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  textDecoration: "none",
                }}
              />{" "}
              IGWTF
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact={"true"}
                  offset={-80}
                >
                  {" "}
                  About
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="discover"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact={"true"}
                  offset={-80}
                >
                  {" "}
                  Projects
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="services"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact={"true"}
                  offset={-80}
                >
                  {" "}
                  The Team
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="transparency"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact={"true"}
                  offset={-80}
                >
                  {" "}
                  Transparency
                </NavLinks>
              </NavItem>

              <NavItem>
                <NavLinks
                  to="sponsorship"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact={"true"}
                  offset={-80}
                >
                  {" "}
                  Sponsorship
                </NavLinks>
              </NavItem>
            </NavMenu>

            <NavBtn>
              <NavBtnLink
                to={{ pathname: "https://gofund.me/26395a55" }}
                target="_blank"
              >
                {" "}
                Support Us
              </NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
