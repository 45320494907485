import React from "react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../images/logo.svg";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElements";

import { NavLinks } from "../Navbar/NavbarElements";

const toggleHome = () => {
  scroll.scrollToTop();
};

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <NavLinks
                to="about"
                smooth={true}
                duration={800}
                spy={true}
                exact={"true"}
                offset={-80}
              >
                {" "}
                About IGWTF
              </NavLinks>
            </FooterLinkItems>

            <FooterLinkItems>
              <NavLinks
                to="discover"
                smooth={true}
                duration={800}
                spy={true}
                exact={"true"}
                offset={-80}
              >
                {" "}
                Our Projects
              </NavLinks>
            </FooterLinkItems>

            <FooterLinkItems>
              <NavLinks
                to="transparency"
                smooth={true}
                duration={800}
                spy={true}
                exact={"true"}
                offset={-80}
              >
                {" "}
                Transparency{" "}
              </NavLinks>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>

        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              <img
                src={logo}
                alt="MeroFx"
                style={{ width: "70px", height: "70px" }}
              />
            </SocialLogo>
            <WebsiteRights>
              IGWTF © {new Date().getFullYear()} All rights reserved Powered by
              CJ Interactive
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/profile.php?id=100070117991681"
                target="_blank"
                arial-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>

              <SocialIconLink
                href="https://instagram.com/ingodwetrust_international?igshid=MzMyNGUyNmU2YQ=="
                target="_blank"
                arial-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>

              <SocialIconLink
                href="https://www.youtube.com/channel/UC_56DPj31FMD7u2K48TxHgw"
                target="_blank"
                arial-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
