import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from "./SidebarElements";
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to="about"
            smooth={true}
            duration={800}
            spy={true}
            exact={"true"}
            offset={-80}
            onClick={toggle}
          >
            About
          </SidebarLink>
          <SidebarLink
            to="discover"
            smooth={true}
            duration={800}
            spy={true}
            exact={"true"}
            offset={-80}
            onClick={toggle}
          >
            Projects
          </SidebarLink>
          <SidebarLink
            to="services"
            smooth={true}
            duration={800}
            spy={true}
            exact={"true"}
            offset={-80}
            onClick={toggle}
          >
            The Team
          </SidebarLink>
          <SidebarLink
            smooth={true}
            duration={1000}
            spy={true}
            exact={"true"}
            offset={-80}
            to="transparency"
            onClick={toggle}
          >
            Transparency
          </SidebarLink>
          <SidebarLink
            to="sponsorship"
            smooth={true}
            duration={800}
            spy={true}
            exact={"true"}
            offset={-80}
            onClick={toggle}
          >
            Sponsorship
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute
            to={{ pathname: "https://gofund.me/26395a55" }}
            target="_blank"
          >
            Donate
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
