import React from "react";
import { Button } from "../ButtonElement2";
import { useInView } from "react-intersection-observer";
import "../../App.css";
import { Link } from "react-router-dom";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from "./InfoElements";

const ChildSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  lightText,
  headline,
  imgTitle,
  darkText,
  description1,
  description2,
  description3,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
  fontBig,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.4,
  });

  return (
    <div>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>
                  {description1} <p /> {description2} <p /> {description3}{" "}
                  <p></p>
                  <p>
                    {" "}
                    Drop us a message on{" "}
                    <a href="https://api.whatsapp.com/send?phone=%2B447361069070&fbclid=IwAR3_PeBZhO_NqUMqtj77wBTn_LN6UkRlX0B0CxDm9s09LtzeRp9GBSreuI0">
                      WhatsApp
                    </a>{" "}
                    to learn more{" "}
                  </p>
                </Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap
                className={inView ? "slider slider-zoom" : "slider"}
                ref={ref}
              >
                <Img src={img} alt={alt} />
              </ImgWrap>
              <BtnWrap>
                <Button
                  to={{ pathname: "https://gofund.me/c2966f4c" }}
                  target="_blank"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact={true}
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                  fontBig={fontBig ? 1 : 0}
                >
                  {buttonLabel}
                </Button>
              </BtnWrap>
              <div
                align="center"
                style={{
                  fontWeight: "bolder",
                  color: "black",
                  fontSize: "20px",
                }}
              >
                <p></p>
                UNITED KINGDOM:
                <p></p>
                Account Name: Ilunga <p> </p>Account Number: 12668167 <p /> Sort
                Code: 11-01-19
                <p></p>
              </div>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </div>
  );
};

export default ChildSection;
