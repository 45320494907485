import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection";
import VideoSection from "../components/VideoSection";
import DonateSection from "../components/DonateSection";
import ContactSection from "../components/ContactSection";

import FinanceSection from "../components/FinanceSection";
import ChildSection from "../components/ChildSection";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import {
  homeObjOne,
  homeObjTwo,
  homeObjThree,
} from "../components/InfoSection/Data";

import { videoObjOne } from "../components/VideoSection/Data";
import { donateObjOne } from "../components/DonateSection/Data";
import { financeObjOne } from "../components/FinanceSection/Data";
import { childObjOne } from "../components/ChildSection/Data";

import {
  contactObjOne,
  contactObjThree,
} from "../components/ContactSection/Data";

import TheTeam from "../components/TheTeam";
import Footer from "../components/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <VideoSection {...videoObjOne} />
      <DonateSection {...donateObjOne} />
      <TheTeam />
      <FinanceSection {...financeObjOne} />
      <ChildSection {...childObjOne} />

      <Footer />
    </>
  );
};

export default Home;
