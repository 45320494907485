import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Helmet } from "react-helmet";

ReactDOM.render(
  <React.StrictMode>
    <>
      <Helmet>
        <tile>In God We Trust Foundation</tile>
        <meta name="description" content="God Trust Foundation" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <App />
    </>
  </React.StrictMode>,
  document.getElementById("root")
);
